/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // Global VARS
                var $root = $('html, body');

                // Small Header Switch
                var body = $('body');
                var nav = $('header.banner nav');
                $(window).scroll(function () {
                    var scroll = $(window).scrollTop();
                    nav.stop();
                    if (scroll >= 136) {
                        body.addClass("navigation-fixed");
                    } else {
                        body.removeClass("navigation-fixed");
                    }
                });

                // quantity input plus/minus buttons
                $('.buttons_added').each(function () {
                    var qty = $(this).find('.input-text.qty');
                    var plus = $(this).find('.plus');
                    var minus = $(this).find('.minus');

                    plus.on('click', function () {
                        qty.val(parseInt(qty.val(), 10) + 1);
                        qty.trigger('change'); // --> cart.js:312
                    });

                    minus.on('click', function () {
                        if (qty.val() > 0) {
                            qty.val(parseInt(qty.val(), 10) - 1);
                            qty.trigger('change');
                        }
                    });
                });
                jQuery(document).on('updated_wc_div', function () {
                    //TODO: function call
                    $('.buttons_added').each(function () {
                        var qty = $(this).find('.input-text.qty');
                        var plus = $(this).find('.plus');
                        var minus = $(this).find('.minus');

                        plus.on('click', function () {
                            qty.val(parseInt(qty.val(), 10) + 1);
                            qty.trigger('change');
                        });

                        minus.on('click', function () {
                            if (qty.val() > 0) {
                                qty.val(parseInt(qty.val(), 10) - 1);
                                qty.trigger('change');
                            }
                        });
                    });
                });

                // hero slider
                $('.hero-slider .slick-carousel').on('init', function (event, slick) {
                    $('.hero-slider').css('visibility', 'visible');
                });
                $('.hero-slider .slick-carousel').slick({
                    prevArrow: '<a class="slick-prev"><span class="glyphicon glyphicon-circle-arrow-left"></span></a>',
                    nextArrow: '<a class="slick-next"><span class="glyphicon glyphicon-circle-arrow-right"></span></a>',
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    fade: true,
                    cssEase: 'linear'
                });
                $('.hero-slider .slick-navigation .prev-arrow').click(function () {
                    $('.hero-slider .slick-carousel').slick('slickPrev');
                });
                $('.hero-slider .slick-navigation .next-arrow').click(function () {
                    $('.hero-slider .slick-carousel').slick('slickNext');
                });

                // video slider
                $('.video-slider .slick-carousel').on('init', function (event, slick) {
                    $('.video-slider').css('visibility', 'visible');
                });
                $('.video-slider .slick-carousel').slick({
                    prevArrow: '<a class="slick-prev"><span class="glyphicon glyphicon-circle-arrow-left"></span></a>',
                    nextArrow: '<a class="slick-next"><span class="glyphicon glyphicon-circle-arrow-right"></span></a>',
                    dots: true,
                    arrows: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    mobileFirst: true,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                dots: false,
                                arrows: true,
                                slidesToShow: 4,
                                slidesToScroll: 1
                            }
                        }
                    ]
                });

                // re-init slick carousel
                $('.slick-carousel').slick('setPosition');

                // AJAX multiple add to cart
                if ($('.box-quickorder').length) {

                    var trigger_ajax = false;
                    var add_all_to_cart_button = $('.box-quickorder .add-all-to-cart');
                    add_all_to_cart_button.click(function (e) {
                        e.preventDefault();
                        var ajaxStack = []; // array to collect ajax requests
                        $('.box-quickorder .qty').each(function () {

                            var prod_id = $(this).attr('data-product-id');
                            var var_id = $(this).attr('data-variation-id');
                            var var_slug = $(this).attr('data-attribute_pa_fuellmenge');
                            var var_qty = $(this).val();

                            if (var_qty > 0) {

                                var ajaxParamsObj = {
                                    prod_id: prod_id,
                                    var_id: var_id,
                                    var_slug: var_slug,
                                    var_qty: var_qty
                                };
                                ajaxStack.push(ajaxParamsObj);

                            }

                        });


                        if (ajaxStack.length > 0) {
                            var i = 0;

                            if (!trigger_ajax) {
                                add_all_to_cart_button.button('loading').removeClass('accent').addClass('lightgrey');
                                trigger_ajax = true;
                            }

                            // self invoking function expression
                            (function makeAjaxRequest() {

                                $.post('?add-to-cart=' + ajaxStack[i].prod_id + '&variation_id=' + ajaxStack[i].var_id + '&attribute_pa_fuellmenge=' + ajaxStack[i].var_slug + '&quantity=' + ajaxStack[i].var_qty, function (data) {
                                    //console.log(data);
                                }).done(function () {
                                    // this call is done
                                    console.log('ajax added to cart: ' + ajaxStack[i].var_id);

                                    // next request
                                    i++;

                                    if (i === ajaxStack.length) {
                                        console.log('all products added');
                                        // redirect here (checkout page)
                                        $(location).attr('href', add_all_to_cart_button.attr('data-url'));
                                    } else {
                                        // recursive call
                                        makeAjaxRequest();
                                    }
                                });

                            })();

                        }

                        return false;
                    });

                }

                // accordeon focus
                $('#accordion').on('shown.bs.collapse', function (e) {
                    var offset = $(this).find('.collapse.in').prev('.panel-heading');
                    var header_height = $('#navbar').height();
                    if (offset) {
                        $root.animate({
                            scrollTop: $(offset).offset().top - header_height - 10
                        }, 500);
                    }
                });

                //GMAPS
                if ($('#googleMap').length && google) {
                    google.maps.event.addDomListener(window, 'load', initialize);
                }

                // News Grid (Masonry)
                var $masonry = $('.grid').masonry({
                    // options...
                    itemSelector: '.grid-item',
                    columnWidth: '.grid-sizer',
                    //gutter: '.gutter-sizer',
                    percentPosition: true,
                });
                // Load more
                $('#load-more-articles').click(function () {
                    $(this).fadeOut();
                    $('.grid .hidden').removeClass('hidden');
                    $masonry.masonry('layout'); // relayout masonry-grid (absolute positioning)
                });

                // Enable possibility to reset product categories dropdown in Saftladen
                $( '.dropdown_product_cat' ).change( function() {
                    if ( $(this).val() === '' ) {
                        location.href = '/saftladen';
                    }
                });

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
